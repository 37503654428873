@import "../../utilities/utilities.scss";

.PDPA {
  @include padding(120px, 100px);
  display: flex;
  gap: 16px;
  flex-direction: column;

  ol {
    padding-left: 2em;
    font-size: 14px;
  }

}