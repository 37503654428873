@import "../utilities/utilities.scss";

.Services {

  .main {
    @include padding(100px);
    //@include background("../images/background-2.jpg", 0, 0.5);
    background: rgb(242, 242, 243);
    display: flex;
    flex-direction: column;
    gap: 32px;

    & > div {
      display: flex;
      background: rgba(255, 255, 255);
      transition: transform 250ms;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
      scroll-margin-top: 100px;

      @media (max-width: 1000px) {
        flex-direction: column;
      }

      p {
        font-size: 16px;
      }

      &:hover {
        transform: scale(102%);
      }

      & > div:first-of-type {
        padding: 32px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-width: 600px;

        ul {
          list-style-position: inside;
        }
      }

      & > div:nth-of-type(2) {
        flex: 1;
        min-width: 300px;
        padding: 32px;
        background: rgba(17, 48, 97, 0.2);

        &.monitoring-graphic {
          display: flex;
          flex-direction: column;
          align-items: center;

          & > div {
            background: #000000;
            padding: 4px 12px;
            border-radius: 16px;
            color: #ffffff;
            display: flex;
            align-items: center;
            gap: 8px;
            align-self: flex-start;

            & > div {
              width: 10px;
              height: 10px;
              background: rgb(0, 255, 0);
              border-radius: 5px;

              &.green {
                background: rgb(0, 200, 0);
              }
            }

            p {
              font-size: 14px;
            }
          }

          h1 {
            flex: 1;
            display: grid;
            place-items: center;
            font-size: 60px;
          }
        }

        &.managed-graphic {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 16px;

          h1 {
            font-size: 16px;
            text-align: center;
          }
          & > div {
            display: grid;
            grid-template-columns: 100px 100px;
            place-items: center;
            grid-gap: 8px;

            & > div {
              background: #113061;
              width: 100px;
              height: 100px;
              border-radius: 8px;
              display: grid;
              place-items: center;
              color: $white;
              background: rgba(0, 200, 0, 0.5);

              &.color {
              background: rgba(255, 0, 0, 0.5)}
            }
          }
        }

        &.incident-graphic {
          display: flex;
          flex-direction: column;
          gap: 16px;
          justify-content: center;

          p {
            text-align: center;
            padding: 8px 16px;
            background: rgba(255, 255, 255, 0.1);
            border-radius: 8px;
            transition: transform 250ms;

            &.color {
              background: $dark-blue;
              color: $white;
              transform: scale(103%);
            }
          }
        }

        &.network-graphic {
          display: flex;
          flex-direction: column;
          gap: 8px;
          justify-content: center;

          div {
            background: rgba(255, 255, 255, 0.7);
            padding: 8px 16px;
            border-radius: 8px;
            display: flex;
            gap: 8px;
            box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);

            &.color {
              background: rgba(255, 255, 255, 1);
            }

            span {
              color: rgb(175, 175, 0);

              &.error {
                color: rgba(255, 0, 0);
              }
            }

            p {
              font-size: 12px;
            }
          }
        }

        &.cloud-graphic {
          display: flex;
          align-items: center;
          flex-direction: column;

          span {
            color: $white;
            font-size: 100px;
          }

          .line {
            flex: 1;
            width: 3px;
            background: $white;
            position: relative;
            border-radius: 3px;

            .circle {
              position: absolute;
              width: 20px;
              height: 20px;
              border-radius: 10px;
              background: $white;
              top: 0;
              transform: translateX(calc(-50% + 1.5px));
              animation: circleAnimation linear 3s alternate infinite;
            }
          }

          @media (max-width: 1000px) {
            flex-direction: row;
            gap: 16px;

            .line {
              height: 3px;
              width: auto;

              .circle {
                transform: translateY(calc(-50% + 1.5px));
                animation: circleHorizontalAnimation linear 3s alternate infinite;
              }
            }

          }
        }

        &.automation-graphic {
          & > div {
            background: rgba(255, 255, 255, 0.7);
            border-radius: 16px;
            overflow: hidden;

            & > div {
              padding: 8px 16px;
              display: flex;
              align-items: center;
              gap: 8px;

              span {
                color: rgba(0, 170, 0);
                max-width: 0;
                overflow: hidden;
                transition: max-width 250ms;
              }

              &.color {
                background: $white;

                span {
                  max-width: 100%;
                }
              }
            }
          }
        }

        &.governance-graphic {
          display: grid;
          place-items: center;

          & > div {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(auto-fill, 150px);
            align-items: center;
            justify-content: center;
            grid-gap: 8px;

            img {
              width: 150px;
              height: 75px;
              object-fit: contain;
              background: $white;
              padding: 8px;
              box-sizing: border-box;
              border-radius: 8px;
              transition: opacity 250ms;

              &.color {
                opacity: 0.6;
              }
            }
          }

        }

        &.consultancy-graphic {
          background: url("../images/consultancy-background.jpg");

          @media (max-width: 1000px) {
            min-height: 300px;
          }
        }

        &.maintenance-graphic {
          display: flex;
          flex-direction: column;
          gap: 8px;

          h1 {
            font-size: 16px;
          }

          & > div {
            background: rgba(255, 255, 255, 0.7);
            padding: 8px 16px;
            border-radius: 8px;
            transition: background 250ms;

            p {
              font-size: 14px;
            }

            p:first-of-type {
              font-weight: bold;
            }

            &.color {
              background: rgba(255, 255, 255, 1);
            }
          }
        }
      }

    }
  }

}