@import "../utilities/utilities.scss";

.Footer {
  background: $black;
  color: $white;
  padding: 50px;

  .main {
    max-width: 1500px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap:32px;


    .links {
      display: flex;
      gap: 100px;
      justify-content: center;
      align-content: flex-start;

      div:not(.contact > div) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      @media (max-width: 800px) {
        flex-direction: column;
        gap: 50px;
        align-items: flex-start;
      }

      h6 {
        padding-bottom: 1em;
      }

      a, p {
        margin-bottom: 0.5em;
      }

      a {
        color: $grey;
        display: block;
        width: auto;

        &:hover, &.active {
          color: $white;
        }
      }

      .contact {
        & > div {
          display: flex;
          gap: 16px;
          padding-bottom: 1em;

          span {
            font-size: 18px;
          }
        }
      }
    }

    .form-links {
      display: flex;
      gap: 16px;

      a {
        padding: 4px 16px;
        color: $grey;
        border: solid 1px $grey;
        transition: background 250ms, color 250ms;

        &:hover {
          background: $grey;
          color: $white;
        }
      }
    }

    hr {
      border-bottom: 1px solid $grey;
    }

    .bottom {
      display: flex;
      justify-content: space-between;

      div {
        display: flex;

        a, pre {
          color: $grey;
        }

        a:hover {
          color: $white;
        }
      }

      @media (max-width: 1100px) {
        flex-direction: column;
        gap: 16px;
      }

      @media (max-width: 600px) {
        div {
          flex-direction: column;
          align-items: flex-start;

          pre {
            display: none;
          }
        }
      }
    }
  }
}