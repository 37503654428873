$dark-blue: #113061;
$black: #000000;
$white: #ffffff;
$grey: #808080;

@mixin background($url, $blur: 0, $brightness: 0) {
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    background: url($url) center center;
    background-size: cover;
    position: absolute;
    inset: 0;
    filter: blur($blur);
    margin: -2 * $blur;
    z-index: -1;
  }

  &::after {
    @if $brightness > 0 {
      background: rgba(255, 255, 255, $brightness);
    } @else {
      background: rgba(0, 0, 0, $brightness * -1);
    }
    content: "";
    //background: rgba(255, 255, 255, $brightness);
    position: absolute;
    inset: 0;
    z-index: 0;
  }

  * {
    z-index: 1;
  }

}

@mixin padding($top: 100px, $bottom: 100px, $first-last: false) {
  padding: $top max((100vw - 1200px) / 2, 100px) $bottom;

  @if $first-last {
    &:first-of-type {
      padding-top: $top * 2;
    }

    &:last-of-type {
      padding-bottom: $bottom * 2;
    }
  }

  @media (max-width: 800px) {
    padding: $top 30px $bottom;
  }
}

@keyframes backgroundAnimation {
  0% {
    transform: scale(150%);
  }
  100% {
    transform: scale(100%);
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
  }
}

@keyframes loopColorAnimation {
  0% {
    background: rgba(255, 0, 0);
  }
  100% {
    background: rgba(0, 255, 0);
  }
}

@keyframes circleAnimation {
  0% {
    top: 0;
  }
  100% {
    top: calc(100% - 20px);
  }
}

@keyframes circleHorizontalAnimation {
  0% {
    left: 0;
  }
  100% {
    left: calc(100% - 20px);
  }
}