@import "../utilities/utilities.scss";

.Company {
  .main {
    @include padding;
    //@include background("../images/background-2.jpg", 0, 0.4);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 100px;

    .vision-mission {
      display: flex;

      div {
        flex: 1;
        text-align: center;
        padding: 40px;

        &:first-of-type {
          border-right: 1px solid rgba(0, 0, 0, 0.3);
        }

        h1 {
          margin-bottom: 18px;
        }

        p {
          font-size: 18px;
        }
      }

      @media (max-width: 800px) {
        flex-direction: column;
        gap: 32px;

        div {
          padding: 0;
        }

        div:first-of-type {
          border-right: none;
        }
      }
    }

    .licenses {
      display: flex;
      gap: 16px;
      flex-direction: column;

      & > h1 {
        text-align: center;
      }

      & > p {
        font-size: 18px;
        margin-bottom: 18px;
      }

      & > div {
        display: flex;
        gap: 50px;

        @media (max-width: 800px) {
          flex-direction: column;
        }

        & > div {
          flex: 1;
          width: 350px;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 8px;

          img {
            width: 200px;
          }
        }
      }

    }

    //& > div {
    //  display: flex;
    //  gap: 50px;
    //
    //  @media (max-width: 1000px) {
    //    flex-direction: column;
    //  }
    //
    //  & > div {
    //    flex: 1;
    //    width: 350px;
    //    display: flex;
    //    flex-direction: column;
    //    align-items: center;
    //    gap: 8px;
    //
    //    img {
    //      width: 200px;
    //      margin-bottom: 16px;
    //    }
    //  }
    //}
  }
}