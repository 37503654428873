@import "../utilities/utilities";

.Home {

  .main {
    //@include background("../images/background-2.jpg", 0, 0.5);
    display: flex;
    flex-direction: column;
    padding: 50px 0 0;
    background: rgb(242, 242, 243);

    .services, .benefits, .fusion {
      @include padding(50px, 50px);
      display: flex;
      flex-direction: column;
      gap: 16px;

      h1 {
        text-align: center;
      }

      p {
        font-size: 18px;
      }

      .services-grid {
        display: grid;
        gap: 20px;
        justify-content: center;
        grid-template-columns: repeat(auto-fit, 300px);
        margin-top: 32px;

        a {
          border-image: linear-gradient(to right, red, blue) 1;
          border-top: solid 3px;
          background: $white;
          padding: 32px 16px;
          box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
          display: flex;
          flex-direction: column;
          gap: 16px;

          &:hover {
            box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.4);
          }

          h2 {
            font-size: 16px;
            text-align: center;
          }

          p {
            font-size: 14px;
            text-align: center;
          }

          button {
            margin-top: auto;
            background: rgb(242, 242, 243);
            cursor: pointer;
            align-self: center;
            padding: 8px 16px;
          }
        }
      }

      .benefits-grid {
        display: flex;
        gap: 50px;
        margin-top: 32px;


        div {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 16px;

          svg {
            fill: $dark-blue;
            font-size: 48px;
          }

          h2 {
            font-size: 18px;
          }

          p {
            font-size: 16px;
          }
        }

        @media (max-width: 1000px) {
          flex-direction: column;
          align-items: center;

          div {
            max-width: 400px;
          }
        }
      }


    }

    .health-check {
      display: flex;
      gap: 32px;
      @include padding(50px, 50px);
      justify-content: center;
      align-items: center;

      img {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 50%;
      }

      div {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        max-width: 400px;

        p {
          font-size: 18px;
        }

        a {
          font-size: 18px;
          padding: 4px 16px;
          background: $dark-blue;
          color: $white;

          &:hover {
            opacity: 0.8;
          }
        }
      }

      @media (max-width: 800px) {
        flex-direction: column-reverse;

        img {
          max-width: 100%;
        }
      }

    }

    //.mission-vision {
    //  display: flex;
    //  gap: 16px;
    //  margin-bottom: 16px;
    //
    //  div {
    //    flex: 1;
    //    padding: 32px 16px;
    //    border-radius: 16px;
    //    background: rgba(255, 255, 255, 0.1);
    //    text-align: center;
    //
    //    p {
    //      font-size: 18px;
    //    }
    //
    //  }
    //
    //  @media (max-width: 800px) {
    //    flex-direction: column;
    //  }
    //
    //}
    //
    //.expertise {
    //  background: rgba(255, 255, 255, 0.1);
    //  border-radius: 16px;
    //  padding: 32px 16px;
    //  display: flex;
    //  flex-direction: column;
    //  align-items: center;
    //  gap: 16px;
    //
    //  div {
    //    width: 100%;
    //    display: grid;
    //    grid-template-columns: repeat(auto-fit, 300px);
    //    align-items: center;
    //    justify-content: center;
    //    gap: 16px;
    //
    //    a {
    //      text-align: center;
    //      padding: 8px 16px;
    //      background: rgba(255, 255, 255, 0.2);
    //      font-size: 18px;
    //      border-radius: 8px;
    //
    //      &:hover {
    //        background: rgba(255, 255, 255, 0.1);
    //      }
    //    }
    //  }
    //
    //}

  }
}