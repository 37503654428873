@import "../utilities/utilities.scss";

.ContactForm {
  background: $white;
  padding: 32px;
  border-image: linear-gradient(to right, red, blue) 1;
  border-top: solid 4px;
  box-shadow: 0 0 10px 0 $grey;
  height: 500px;

  h1 {
    font-size: 24px;
    margin-bottom: 24px;
  }

  form {
    display: grid;
    grid-template-columns: minmax(120px, 240px) minmax(120px, 240px);
    gap: 0 16px;

    div {
      input {
        border-bottom: 1px solid $dark-blue;
        width: 100%;
        height: 30px;
        box-sizing: border-box;

        &:focus {
          outline: none;
          border-bottom: 2px solid $dark-blue;
        }
      }

      p {
        height: 20px;
      }

      p:not(.error) {
        margin-top: 4px;
        color: $dark-blue;
      }

      .error {
        color: #f5222d;
        font-size: 12px;
      }

      &:not(:nth-of-type(2), :nth-of-type(3)) {
        grid-column: 1 / 3;
      }

      button {
        width: 100%;
        margin-top: 8px;
        padding: 4px;
        color: $white;
        background: $dark-blue;
        cursor: pointer;

        &:hover {
          opacity: 0.9;
        }
      }
    }

    .form-type {
      margin-bottom: 16px;
      width: fit-content;

      span {
        font-size: 14px;
        font-family: Poppins, sans-serif;
      }
    }
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    padding: 32px;
    gap: 16px;
    height: auto;

    form {
      width: 100%;
      grid-template-columns: 1fr;
      grid-gap: 0;

      div {
        grid-column: 1 / 2;
      }
    }
  }

}