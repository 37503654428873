@import "../utilities/utilities.scss";

.Header {
  padding: 0 28px;
  margin: auto;
  position: fixed;
  top: 0;
  z-index: 2;
  @include padding(0, 0);
  width: 100%;
  box-sizing: border-box;
  transition: box-shadow 500ms;
  background: white;
  //background: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  //user-select: none;

  .nav {
    display: flex;
    justify-content: space-between;
    height: 72px;
    transition: height 500ms;

    .menu-button {
      display: none;
      cursor: pointer;
      margin-right: 28px;
    }

    .logo {
      display: grid;
      place-items: center;

      img {
        width: 200px;
      }
    }

    .nav-links {
      display: flex;

      a, & > div {
        display: grid;
        place-items: center;
        padding: 14px;
        position: relative;

        &:hover, &.active {
          font-weight: bold;
        }

        &:hover {
          background: rgba(255, 255, 255, 0.1);

          @media (min-width: 1051px) {
            div {
              display: flex;
              flex-direction: column;
            }
          }
        }

        p {
          cursor: pointer;
        }

        div {
          display: none;
          position: absolute;
          top: 72px;
          background: #f7f7f7;
          width: 200px;

          a {
            font-weight: normal;
          }
        }
      }

    }

    @media (max-width: 1050px) {
      justify-content: flex-start;

      .menu-button {
        display: grid;
        place-items: center;
      }

      .nav-links {
        position: absolute;
        top: 72px;
        left: 0;
        flex-direction: column;
        background: $white;
        backdrop-filter: blur(16px);
        width: 100%;
        max-height: 0;
        overflow: auto;
        transition: max-height 500ms, top 500ms;
        z-index: 100;
        box-shadow: 0 4px 10px -5px $grey;

        &::-webkit-scrollbar {
          display: none;
        }

        & > div {

          div {
            position: static;
            width: 100%;
            display: flex;
            flex-direction: column;
            max-height: 0;
            margin-top: 0;
            overflow: hidden;
          }

          .expand {
            margin-top: 14px;
            max-height: 100%;
          }
        }

        &.opened {
          max-height: 90vh;
        }
      }
    }

  }

  &.scroll {
    box-shadow: 0 4px 10px -5px $grey;
    //.nav {
    //  height: 72px;
    //
    //  .nav-links {
    //    top: 72px;
    //  }
    //}
  }

}