@import "../../utilities/utilities";

.XdrSoc {

  .main {
    display: flex;
    flex-direction: column;
    background: rgb(242, 242, 243);
    padding: 50px 0;

    .why, .how-it-works, .benefits {
      @include padding(50px, 50px);
      display: flex;
      flex-direction: column;
      scroll-margin-top: 50px;

      & > p {
        color: #5a5a5a;
      }

      h1 {
        margin-bottom: 32px;
      }

      img {
        width: 50px;
        margin-bottom: 8px;
      }

      .why-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 32px;

        @media (max-width: 800px) {
          grid-template-columns: 1fr;
        }

        div {
          background: $white;
          padding: 32px;
          border-image: linear-gradient(to right, red, blue) 1;
          border-top: solid 3px;
          display: flex;
          flex-direction: column;
          gap: 8px;
          box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
          scroll-margin-top: 100px;
        }
      }

      .benefits-grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 32px;

        div {
          background: #e9e9e9;
          padding: 32px;
          display: flex;
          flex-direction: column;
          gap: 8px;
        }

        @media (max-width: 1000px) {
          grid-template-columns: 1fr;
        }
      }

      .how-it-works-graphic {

        .tabs {
          display: flex;
          justify-content: space-around;

          p {
            font-size: 16px;
            color: #919191;
            cursor: pointer;
            transition: border-bottom 250ms, color 250ms;

            &.active, &:hover {
              color: $dark-blue;
              font-weight: bold;
              border-bottom: 4px solid $dark-blue;
            }
          }
        }

        .tab-content {
          background: url("../../images/xdr-graphic.png") center center no-repeat;
          background-size: contain;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 16px;
          height: 400px;
          padding: 50px 0;
          box-sizing: border-box;

          div {
            max-width: 400px;
            padding: 16px;
            border-radius: 8px;
            background: rgba(255, 255, 255, 0.8);
            backdrop-filter: blur(8px);


            h2 {
              font-size: 18px;
              margin-bottom: 8px;
            }

            &:first-of-type {
              align-self: flex-start;
            }

            &:nth-of-type(2) {
              align-self: flex-end;
            }
          }

        }
      }
    }
  }

}