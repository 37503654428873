@import "../../utilities/utilities";

.Systems {

  .main {
    display: flex;
    flex-direction: column;
    background: rgb(242, 242, 243);
    padding: 50px 0;

    .risk-based-approach {
      @include padding(50px, 50px);
      display: flex;
      flex-direction: column;
      gap: 8px;

      & > p:first-of-type {
        color: #5a5a5a;
        font-size: 14px;
      }

      p {
        font-size: 16px;
      }

      & > div {
        display: flex;
        gap: 32px;
        align-items: center;

        div {
          display: flex;
          gap: 16px;

          img {
            width: 80px;
            height: 80px;
            object-fit: contain;
            background: $white;
            padding: 8px;
            box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
          }
        }

        @media (max-width: 1000px) {
          flex-direction: column;
        }

      }
    }

    .services-and-products {
      @include padding(50px, 50px);
      display: flex;
      flex-direction: column;

      & > p {
        color: #5a5a5a;
      }

      h1 {
        margin-bottom: 32px;
      }

      .services-and-products-grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 32px;

        div {
          background: $white;
          padding: 32px;
          border-image: linear-gradient(to right, red, blue) 1;
          border-top: solid 3px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);

          p {
            margin-bottom: 16px;
          }

          a {
            margin-top: auto;
            background: rgb(242, 242, 243);
            padding: 8px 16px;
          }
        }

        @media (max-width: 1000px) {
          grid-template-columns: 1fr;
        }
      }
    }

    .contact {
      @include padding(50px, 50px);
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;

      p {
        text-align: center;
        font-size: 18px;
      }

      a {
        background: #e9e9e9;
        padding: 8px 16px;

        &:hover {
          background: #d9d9d9;
        }
      }


    }

  }

}