@import "../utilities/utilities.scss";

.Banner {
  user-select: none;

  .banner-item {
    @include padding(100px, 100px);
    //@include background("../images/background-3.jpg", 0, 0);
    display: flex;
    align-items: center;
    min-height: 100vh;

    @media (max-width: 800px) {
      padding-left: 50px;
      padding-right: 50px;
    }

    img {
      max-width: 40%;
    }

    &.home {
      //&::before {
      //  animation: backgroundAnimation 20s ease-in-out alternate infinite;
      //}

      .links {
        align-self: flex-start;
        margin-top: 20px;
        display: flex;
        gap: 8px;
        a {
          background: $dark-blue;
          color: $white;
          padding: 4px 8px;

          &:last-of-type {
            color: $dark-blue;
            border: 1px solid $dark-blue;
            background: $white;
          }
        }
      }
    }

    //&.services {
    //  @include padding(150px);
    //  @include background("../images/services-background.jpg", 2px, -0.5);
    //}
    //
    //&.company {
    //  @include padding(150px);
    //  @include background("../images/company-background.png", 2px, 0.5);
    //  &::after {
    //    background: linear-gradient(to right, rgba(255, 255, 255, 1) 600px, rgba(255, 255, 255, 0));
    //  }
    //  p {
    //    max-width: 500px;
    //  }
    //}
    //
    //&.contact {
    //  @include background("../images/contact-background.png", 0, 0.5);
    //  @include padding(50px, 50px);
    //  &::after {
    //    background: linear-gradient(to right, rgba(255, 255, 255, 1) 600px, rgba(255, 255, 255, 0));
    //  }
    //  p {
    //    max-width: 500px;
    //  }
    //}

    h1, h2, p, a, img {
      text-align: start;
      animation: fadeInAnimation ease-in 1s;
    }

    h1 {
      font-size: 80px;
    }
    h2 {
      font-size: 40px;
      padding-bottom: 20px;
    }
    p {
      font-size: 20px;
      padding-bottom: 0.5em;
    }
    a {
      font-size: 16px;
    }

    @media (max-width: 800px) {
      img {
        display: none;
      }
      h1 {
        font-size: 64px;
      }
      h2 {
        font-size: 32px;
      }
      p {
        font-size: 16px;
      }
      a {
        font-size: 14px;
      }
    }

  }

}