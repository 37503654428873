@import "../utilities/utilities.scss";

.MailingForm {
  display: flex;
  gap: 16px;
  flex-direction: column;

  div {

    h1 {
      font-size: 18px;
    }

    p {
      max-width: 300px;
    }
  }

  form {

    div {
      input {
        width: 300px;
        border: 2px solid $dark-blue;
        height: 40px;
        padding: 0 16px;
        box-sizing: border-box;

        &:focus {
          outline: none;
        }
      }

      button {
        width: 100px;
        height: 40px;
        background: $dark-blue;
        color: $white;
        cursor: pointer;

        &:hover {
          opacity: 0.9;
        }
      }
    }

    p {
      height: 18px;
      font-size: 12px;
      margin-top: 4px;
      color: $grey;
      padding: 0 16px;
    }


  }

  @media (max-width: 1000px) {
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;

    form {
      div {
        input {
          width: 200px;
        }
        button {
          width: 80px;
        }
      }
    }
  }

}