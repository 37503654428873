@import "../utilities/utilities.scss";

.Contact {
  // @include background("../images/contact-background.png", 0, -0.5);
  input {
    background: transparent;
  }

  .main {
    display: flex;
    gap: 32px;
    @include padding(100px, 100px);

    div {
      scroll-margin-top: 100px;
    }

    .details-map {
      flex: 1;
      background: white;
      border-image: linear-gradient(to right, red, blue) 1;
      border-top: solid 5px;
      display: flex;
      flex-direction: column;
      box-shadow: 0 0 10px 0 $grey;

      .details {
        padding: 32px;

        h2 {
          margin-bottom: 8px;
        }

        & > p {
          margin-bottom: 8px;
        }

        p {
          font-size: 16px;
        }

        div {
          display: grid;
          grid-template-columns: 100px 1fr;
          gap: 8px;
        }
      }

      .map {
        flex: 1;
      }
    }

    @media (max-width: 1000px) {
      flex-direction: column;
      .details-map {
        flex: auto;
        height: 600px;
      }
    }

    //.map {
    //  width: 50%;
    //  height: 500px;
    //}
    //
    //.details {
    //  padding: 50px;
    //
    //  h2 {
    //    margin-bottom: 8px;
    //  }
    //
    //  p {
    //    font-size: 16px;
    //  }
    //
    //  div {
    //    display: grid;
    //    grid-template-columns: 100px 1fr;
    //    gap: 8px;
    //    margin-top: 16px;
    //  }
    //}
    //
    //@media (max-width: 800px) {
    //  flex-direction: column;
    //
    //  .map {
    //    width: 100%;
    //  }
    //}

  }

}