@import "../../utilities/utilities.scss";

.Legal {
  @include padding(150px, 100px);
  display: flex;
  flex-direction: column;
  gap: 32px;

  div {
    p:first-of-type {
      font-weight: bold;
    }
  }
}