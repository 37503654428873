@font-face {
  font-family: Poppins;
  src: url("fonts/Poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Material Icons";
  src: url("fonts/MaterialIcons/MaterialIcons-Regular.ttf");
}

* {
  padding: 0;
  margin: 0;
  border: 0;
  font-family: Poppins, sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
}

a, p, h6 {
  font-size: 14px;
}

a {
  text-decoration: none;
  color: #000000;
}

div {
  box-sizing: content-box;
}

html {
  //noinspection CssInvalidPropertyValue
  //overflow-y: overlay;
}

.material-icons {
  //noinspection CssNoGenericFontName
  font-family: "Material Icons";
}